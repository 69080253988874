import { createContext, useContext } from 'react';
var LegalProvisionContext = createContext({
    legalProvisionContext: null,
    setLegalProvisionContext: function (value) { },
    clearLegalProvisionContext: function () { },
    entityIdHeaderValue: '',
    setCurrentId: function () { return ''; },
    currentId: null,
    setMachineState: function () { },
    machineState: { type: 'SHOW_INITIAL_PARAGRAPH' },
    requiredPermission: 'INTERNAL_ACCESS',
    oldLegalProvision: null,
    setOldLegalProvision: function () { },
    currentLegalProvision: null,
    setCurrentLegalProvision: function () { },
    filteredRoot: null,
    setFilteredRoot: function () { return ''; },
    activeSearchMode: false,
    setActiveSearchMode: function () { return false; },
});
export var useLegalProvisionContext = function () {
    var context = useContext(LegalProvisionContext);
    if (!context) {
        throw new Error('useLegalProvisionContext must be used within a LegalProvisionContextProvider');
    }
    return context;
};
export default LegalProvisionContext;
